<template>
  <Card tag="form" @submit.prevent theme="border" autocomplete="on" class="bid-create-personal-information">
    <template #header>
      <h1>{{ $t('personalInformation') }}</h1>
    </template>
    <template #default>
      <template v-for="(group, groupIndex) in fields">
        <div class="bid-create-personal-information__inputs" :key="groupIndex">
          <ChoiceComponent
            :key="groupIndex + 'choice'"
            v-if="groupIndex === 0"
            class="bid-create-personal-information__choice"
            v-model="value.applicant.isCompany"
            :values="isCompanychoiceValues"
          />

          <div
            v-if="value.hasCoApplicant && groupIndex === 1"
            :key="groupIndex + 'choice'"
            class="bid-create-personal-information__coApplicant"
          >
            <h2>{{ $t('bidCoApplicant') }}</h2>
            <ChoiceComponent
              class="bid-create-personal-information__choice"
              v-model="value.coApplicant.isCompany"
              :values="isCompanychoiceValues"
            >
            </ChoiceComponent>
          </div>

          <InputComponent
            v-for="(field, index) in group"
            :key="index"
            :label="labels[field.key]"
            :value="$path(field.key, value)"
            @input="$ev => setValue(field.key, $ev)"
            :type="field.type || 'text'"
            required
            :validate="validator.isFieldValid(field.key)"
            :validation-message="validator.getFieldError(field.key)"
            :mask="field.mask || {}"
            :autocomplete="field.autocomplete || false"
          />

          <CheckboxComponent v-if="groupIndex === 0" v-model="value.hasCoApplicant" :label="labels['hasCoApplicant']" />
        </div>
      </template>

      <CustomFields
        class="mt-2"
        v-model="value.customFields"
        :schema="customFieldSchemas"
        :placement="customFieldsPlacement"
        :validator="validator"
        validator-prefix="customFields"
      />
    </template>
    <template #footer>
      <slot name="navigation" />
    </template>
  </Card>
</template>

<script>
import { i18n } from '@/i18n'
import { mapState } from 'vuex'
import { mapi18n } from '@/i18n'
import Validator from '@/mixins/validator'

import Card from '@/components/Card'
import { InputComponent } from 'vue-elder-input'
import { CheckboxComponent } from 'vue-elder-checkbox'
import CustomFields from '@kvass/custom-fields'
import { GetCustomFieldsRules } from '@kvass/custom-fields/src/utils'
import { ChoiceComponent } from 'vue-elder-choice'
import { setByPath } from '@/utils'

const meta = {
  order: 1,
  id: 'personal-information',
  label: i18n.tc('personalInformation'),
  icon: ['fal', 'file-alt'],
}
function isValidCondition(field) {
  return !('condition' in field) || field.condition
}

const Personi18nBuilder = prefix => {
  return {
    [`${prefix}.companyName`]: 'companyName',
    [`${prefix}.organizationNumber`]: 'orgNo',
    [`${prefix}.socialSecurityNumber`]: 'socialSecurityNumber',
    [`${prefix}.name`]: 'name',
    [`${prefix}.email`]: 'email',
    [`${prefix}.phone`]: 'phone',
    [`${prefix}.address.street`]: 'street',
    [`${prefix}.address.postcode`]: 'postcode',
    [`${prefix}.address.city`]: 'city',
  }
}

const Rules = {
  'applicant.companyName': `required_if:applicantCompanyFieldsRequired,${i18n.t('selected')}`,
  'applicant.organizationNumber': `required_if:applicantCompanyFieldsRequired,${i18n.t('selected')}`,
  'applicant.socialSecurityNumber': `required|socialSecurityNumber|different:coApplicant.socialSecurityNumber`,
  'applicant.name': 'required',
  'applicant.email': 'required|email|different:coApplicant.email',
  'applicant.phone': 'required|mobile',
  'applicant.address.street': 'required',
  'applicant.address.postcode': 'required',
  'applicant.address.city': 'required',

  'coApplicant.companyName': `required_if:coApplicantCompanyFieldsRequired,${i18n.t('selected')}`,
  'coApplicant.organizationNumber': `required_if:coApplicantCompanyFieldsRequired,${i18n.t('selected')}`,
  'coApplicant.socialSecurityNumber': `required_if:hasCoApplicant,${i18n.t(
    'yes',
  )}|socialSecurityNumber|different:applicant.socialSecurityNumber`,
  'coApplicant.name': `required_if:hasCoApplicant,${i18n.t('yes')}`,
  'coApplicant.email': `required_if:hasCoApplicant,${i18n.t('yes')}|email|different:applicant.email`,
  'coApplicant.phone': `required_if:hasCoApplicant,${i18n.t('yes')}|mobile`,
  'coApplicant.address.street': `required_if:hasCoApplicant,${i18n.t('yes')}`,
  'coApplicant.address.postcode': `required_if:hasCoApplicant,${i18n.t('yes')}`,
  'coApplicant.address.city': `required_if:hasCoApplicant,${i18n.t('yes')}`,
}
const Labels = {
  hasCoApplicant: 'bidHasCoApplicant',
  applicantCompanyFieldsRequired: 'company',
  coApplicantCompanyFieldsRequired: 'company',
  ...Personi18nBuilder('applicant'),
  ...Personi18nBuilder('coApplicant'),
}

export { meta, Labels as labels, Rules as rules }
export default {
  mixins: [Validator],
  props: {
    value: Object,
    customFieldSchemas: { type: Array, default: () => [] },
    customFieldsLabels: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      customFieldsPlacement: 'bid:personal-information',

      validator: {
        rules: 'rules',
        labels: 'labels',
        data: 'data',
      },
    }
  },
  computed: {
    ...mapi18n('i18n', Labels),
    labels() {
      return {
        ...this.i18n,
        ...this.customFieldsLabels,
      }
    },
    coApplicantCompanyCondition() {
      return this.value.hasCoApplicant && this.value.coApplicant.isCompany
    },
    fields() {
      return [
        //applicant
        [
          {
            key: 'applicant.companyName',
            condition: this.value.applicant.isCompany,
          },
          {
            key: 'applicant.organizationNumber',
            condition: this.value.applicant.isCompany,
          },
          {
            key: 'applicant.socialSecurityNumber',
            mask: { mask: '000000 00000', unmask: true },
          },
          {
            key: 'applicant.name',
            autocomplete: 'name',
          },
          {
            key: 'applicant.email',
            autocomplete: 'email',
            type: 'email',
          },
          {
            key: 'applicant.phone',
            autocomplete: 'tel',
            type: 'number',
          },
          {
            key: 'applicant.address.street',
            autocomplete: 'street-address',
          },
          {
            key: 'applicant.address.postcode',
            autocomplete: 'postal-code',
            type: 'number',
          },
          {
            key: 'applicant.address.city',
            autocomplete: 'address-level2',
          },
        ].filter(isValidCondition),

        //coApplicant
        [
          {
            key: 'coApplicant.companyName',
            condition: this.coApplicantCompanyCondition,
          },
          {
            key: 'coApplicant.organizationNumber',
            condition: this.coApplicantCompanyCondition,
          },
          {
            key: 'coApplicant.socialSecurityNumber',
            mask: { mask: '000000 00000', unmask: true },
            condition: this.value.hasCoApplicant,
          },
          {
            key: 'coApplicant.name',
            autocomplete: 'name',
            condition: this.value.hasCoApplicant,
          },
          {
            key: 'coApplicant.email',
            autocomplete: 'email',
            type: 'email',
            condition: this.value.hasCoApplicant,
          },
          {
            key: 'coApplicant.phone',
            autocomplete: 'tel',
            type: 'number',
            condition: this.value.hasCoApplicant,
          },
          {
            key: 'coApplicant.address.street',
            autocomplete: 'street-address',
            condition: this.value.hasCoApplicant,
          },
          {
            key: 'coApplicant.address.postcode',
            autocomplete: 'postal-code',
            type: 'number',
            condition: this.value.hasCoApplicant,
          },
          {
            key: 'coApplicant.address.city',
            autocomplete: 'address-level2',
            condition: this.value.hasCoApplicant,
          },
        ].filter(isValidCondition),
      ]
    },
    data() {
      return {
        ...this.value,
        hasCoApplicant: this.value.hasCoApplicant ? this.$t('yes') : this.$t('no'),
        applicantCompanyFieldsRequired: this.value.applicant.isCompany ? this.$t('selected') : this.$t('no'),
        coApplicantCompanyFieldsRequired: this.coApplicantCompanyCondition ? this.$t('selected') : this.$t('no'),
      }
    },
    isCompanychoiceValues() {
      return [
        { value: false, label: this.$t('private') },
        { value: true, label: this.$t('company') },
      ]
    },
    rules() {
      return {
        ...Rules,
        ...GetCustomFieldsRules(this.customFieldSchemas, { placement: this.customFieldsPlacement }),
      }
    },
  },
  methods: {
    setValue(path, value) {
      return setByPath(path, value, this.value)
    },
  },
  created() {
    if (this.value.id) this.$emit('next')
  },
  components: {
    Card,
    InputComponent,
    CheckboxComponent,
    CustomFields,
    ChoiceComponent,
  },
}
</script>

<style lang="scss">
.bid-create-personal-information {
  margin: 0 auto;

  &__choice {
    margin-bottom: -0.5rem;

    @media (min-width: 800px) {
      grid-column-end: span 2;
    }

    .elder-choices__item-label {
      padding: 0.5rem 2rem;
    }
    .elder-choices__item {
      border-radius: 50px;
      flex-grow: 0;
    }
  }

  .card__header {
    min-height: 125px;
  }

  h1 {
    margin: 0;
  }
  &__coApplicant {
    h2 {
      padding-bottom: 1rem;
    }

    @media (min-width: 800px) {
      grid-column-end: span 2;
    }

    padding: 1rem 0 1rem;
  }

  &__inputs {
    display: grid;
    grid-gap: 1rem 2rem;
    align-items: flex-start;
    grid-template-columns: 1fr;

    @media (min-width: 800px) {
      grid-template-columns: repeat(2, 1fr);

      .elder-checkbox,
      h2 {
        grid-column-end: span 2;
      }
    }

    h2 {
      margin: 2rem 0 0;
    }
  }
}
</style>
