<template>
  <div class="financing">
    <InputComponent
      v-model="value.name"
      :label="labels['name']"
      required
      :validate="validator.isFieldValid('name')"
      :validation-message="validator.getFieldError('name')"
    />
    <InputComponent
      v-model="value.phone"
      :label="labels['phone']"
      required
      type="tel"
      :validate="validator.isFieldValid('phone')"
      :validation-message="validator.getFieldError('phone')"
    />

    <InputComponent
      v-model="value.email"
      v-if="fields.includes('email')"
      :label="labels['email']"
      type="email"
      :validate="validator.isFieldValid('email')"
      :validation-message="validator.getFieldError('email')"
    />
    <InputComponent
      v-model="value.bank"
      :label="labels['bank']"
      :validate="validator.isFieldValid('bank')"
      :validation-message="validator.getFieldError('bank')"
    />

    <InputComponent
      :label="labels['amount']"
      v-model.number="value.amount"
      required
      suffix="NOK"
      :mask="{ mask: Number, thousandsSeparator: ' ', unmask: true }"
      :validate="validator.isFieldValid('amount')"
      :validation-message="validator.getFieldError('amount')"
      data-field="amount"
    />
  </div>
</template>

<script>
import { i18n } from '@/i18n'
import { mapi18n } from '@/i18n'
import Validator from '@/mixins/validator'

import { InputComponent } from 'vue-elder-input'

const Rules = {
  amount: 'required',
  phone: 'required|mobile',
  name: 'required',
  email: 'email',
}

const Labels = {
  amount: 'amount',
  phone: 'phone',
  name: 'financeContact',
  bank: 'bank',
  email: 'email',
}

export { Labels as labels, Rules as rules }
export default {
  mixins: [Validator],
  props: {
    value: Object,
    fields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      validator: {
        rules: Rules,
        labels: 'labels',
        data: 'value',
      },
    }
  },
  computed: {
    ...mapi18n('labels', Labels),
  },
  components: {
    InputComponent,
  },
}
</script>

<style lang="scss">
.financing {
  display: grid;
  grid-gap: 1rem 2rem;
  align-items: flex-start;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
</style>
