import { setByPath, getByPath } from '@/utils'

export default function (key, storageName, storage = window.sessionStorage) {
  return {
    methods: {
      clearStorage() {
        storage.removeItem(storageName)
      },
    },
    created() {
      try {
        let data = JSON.parse(storage.getItem(storageName))
        if (data) setByPath(key, data, this)
      } catch (err) {}

      this.$watch(
        key,
        () => {
          let data = getByPath(key, this)
          if (!data) return
          storage.setItem(storageName, JSON.stringify(data))
        },
        { deep: true, immediate: true },
      )
    },
  }
}
