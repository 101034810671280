<template>
  <Card class="bid-create-confirmation" theme="border">
    <template #header>
      <h1>{{ $t('buyConfirmation') }}</h1>
    </template>
    <template>
      <Icon :icon="['fad', 'check-circle']" size="2x" />
      <div class="bid-create-confirmation__message">{{ $t('bidSignedMessage') }}</div>
      <ButtonComponent
        :label="$t('gotoProject')"
        tag="a"
        :href="$path('project.url', item)"
        theme="primary"
        :icon="['fal', 'angle-right']"
      />
    </template>
  </Card>
</template>

<script>
import { i18n } from '@/i18n'
import { mapState } from 'vuex'
import Card from '@/components/Card'

const meta = {
  order: 4,
  id: 'confirmation',
  label: i18n.tc('buyConfirmation'),
  icon: ['fal', 'file-alt'],
}

export { meta }
export default {
  props: {
    value: Object,
    labels: Object,
    validator: Object,
  },
  computed: {
    ...mapState('Residential', ['item']),
  },
  components: {
    Card,
  },
}
</script>

<style lang="scss">
.bid-create-confirmation {
  text-align: center;

  .card__header {
    min-height: 125px;
  }

  &__message {
    max-width: 400px;
    line-height: 1.5;
    margin: 1rem auto 2rem;
  }

  h1 {
    margin: 0;
  }
}
</style>
