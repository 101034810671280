<template>
  <Card tag="form" @submit.prevent theme="border" autocomplete="on" class="bid-create-bid-information">
    <template #header>
      <h1>{{ $t('bidInformation') }}</h1>
    </template>
    <template #default>
      <div class="bid-create-bid-information__inputs">
        <div data-field="amount">
          <InputComponent
            :label="labels[$path('sale.fixed', item) ? 'price' : 'amount']"
            v-model.number="value.amount"
            required
            suffix="NOK"
            :disabled="$path('sale.fixed', item)"
            :mask="{ mask: Number, thousandsSeparator: ' ', unmask: true }"
            :validate="validator.isFieldValid('amount')"
            :validation-message="validator.getFieldError('amount')"
            :sublabel="!$path('sale.fixed', item) ? $t('addDesiredAmount') : undefined"
          />

          <div v-if="$path('sale.fixed', item)" class="bid-create-bid-information__price">
            <span>
              <template v-if="$path('sale.fee.included', item)">
                {{ $t('residentialSaleFeeIncluded', { value: null }) }}
              </template>
              <template v-else> + {{ $t('residentialSaleFee').toLowerCase() }} </template>
              <template v-if="fee">({{ fee | Currency }})</template>
            </span>
            <span v-if="$path('sale.jointDebt', item)">
              <template>+ {{ $t('jointDebt').toLowerCase() }}</template>
              <template v-if="jointDebt"> ({{ jointDebt | Currency }})</template>
            </span>

            <span v-if="total && total !== $path('sale.value', item)" class="bid-create-bid-information__price-total"
              >{{ `${$t('totalPrice')}:` }} <template> {{ total | Currency }}</template></span
            >
          </div>
        </div>

        <InputComponent
          :value="value.condition"
          v-if="showCondition"
          :label="labels['condition']"
          :validate="validator.isFieldValid('condition')"
          :validation-message="validator.getFieldError('condition')"
          data-field="condition"
        >
          <textarea v-model="value.condition" class="elder-input__element" rows="5" style="resize: vertical"></textarea>
        </InputComponent>
        <InputComponent
          v-if="showExpiresAt"
          :value="expiresAtDate"
          :label="labels['expiresAt']"
          required
          data-field="expiresAt"
        >
          <InputDate v-model="expiresAtDate" :min="expiresAtMin" />
          <input v-model.lazy="expiresAtTime" class="elder-input__element" type="time" required />
        </InputComponent>
        <InputComponent
          v-if="showTakeoverAt"
          :value="takeoverAt"
          required
          :label="labels['takeoverAt']"
          :is-valid="validator.fields['takeoverAt'].valid"
          :validation-message="validator.fields['takeoverAt'].error"
        >
          <InputDate v-model="takeoverAt" :min="takeoverAtMin" />
        </InputComponent>
      </div>

      <template v-if="showFinancing">
        <hr />
        <h2>{{ $t('financingPlan') }}</h2>

        <FinancingComponent
          v-for="(financing, index) in value.financing"
          :fields="
            ($path('project.contractTemplates.bidFields', item) || [])
              .filter(i => i && i.startsWith(`Bid.financing.${index}`))
              .map(i => i.split(`${index}.`)[1])
          "
          :key="index"
          :value="financing"
        />

        <div class="g-1 mt-4">
          <ButtonComponent
            v-if="financingCount !== value.financing.length"
            :label="$t('add')"
            :icon="['fal', 'plus']"
            @click="addFinancing"
          />
          <ButtonComponent
            v-if="value.financing.length !== 1"
            :label="$t('delete')"
            :icon="['fal', 'minus']"
            @click="removeFinancing"
          />
        </div>
      </template>

      <CustomFields
        class="mt-2"
        v-model="value.customFields"
        :schema="customFieldSchemas"
        :data="value"
        placement="bid:bid-information"
        :validator="validator"
        validator-prefix="customFields"
      />
    </template>
    <template #footer>
      <slot name="navigation" />
    </template>
  </Card>
</template>

<script>
import { mapi18n, i18n } from '@/i18n'
import { mapState, mapGetters } from 'vuex'
import { Currency } from '@/filters'

import Validator from '@/mixins/validator'
import moment from 'moment'
import { DateTransformer, TimeTransformer } from '@/utils/transformers'
import Financing from '@/models/Financing'
import CustomFields from '@kvass/custom-fields'
import InputDate from '@/components/InputDate'
import { GetCustomFieldsRules } from '@kvass/custom-fields/src/utils'

import Card from '@/components/Card'
import { InputComponent } from 'vue-elder-input'
import FinancingComponent, { rules as FinancingRules } from '@/components/Financing'

const meta = {
  order: 2,
  id: 'bid-information',
  label: i18n.tc('bidInformation'),
  icon: ['fal', 'file-alt'],
}

const Rules = {
  amount: 'required',
  condition: 'max:250',
  ...Object.fromEntries(Object.entries(FinancingRules).map(([key, value]) => [`financing.*.${key}`, value])),
}

const Labels = {
  price: 'price',
  amount: 'amount',
  expiresAt: 'bidExpiresAt',
  takeoverAt: 'bidTakeoverAt',
  condition: 'bidCondition',
}

export { meta, Labels as labels, Rules as rules }
export default {
  mixins: [Validator],
  props: {
    value: Object,
    customFieldSchemas: { type: Array, default: () => [] },
    customFieldsLabels: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    showExpiresAt: {
      handler(val) {
        if (val)
          this.value.expiresAt = moment()
            .add(10, 'days')
            .hour(12)
            .startOf('hour')
            .toDate()
      },
      immediate: true,
    },
    showFinancing: {
      handler(val) {
        if (!val || this.value.financing.length) return
        this.addFinancing()
      },
      immediate: true,
    },
    isFixedSale: {
      handler(val) {
        if (val) this.value.amount = this.$path('sale.value', this.item)
      },
      immediate: true,
    },
  },
  data() {
    return {
      customFieldsPlacement: 'bid:bid-information',
      validator: {
        rules: 'rules',
        labels: 'labels',
        data: 'value',
      },
    }
  },
  computed: {
    ...mapState('Residential', ['item', 'promises']),
    ...mapGetters('Residential', ['isFixedSale']),
    ...mapi18n('i18n', Labels),
    labels() {
      return {
        ...this.i18n,
        ...this.customFieldsLabels,
      }
    },
    expiresAtDate: DateTransformer('value.expiresAt'),
    expiresAtTime: TimeTransformer('value.expiresAt'),
    expiresAtMin() {
      return moment()
        .add(3, 'd')
        .format('YYYY-MM-DD')
    },
    takeoverAtMin() {
      if (!this.value.expiresAt) return
      return moment(this.value.expiresAt)
        .add(1, 'd')
        .format('YYYY-MM-DD')
    },
    takeoverAt: DateTransformer('value.takeoverAt'),
    showFinancing() {
      return (this.$path('item.project.contractTemplates.bidFields') || []).find(f => f.startsWith('Bid.financing'))
    },

    financingCount() {
      const items = (this.$path('item.project.contractTemplates.bidFields') || []).filter(f =>
        f.startsWith('Bid.financing'),
      )

      if (!items) return []

      let result = []
      items.forEach(element => {
        const match = element.match(/(\d+)/g)
        if (result.includes(match[0])) return
        result.push(match[0])
      })

      return result.length
    },

    showCondition() {
      return (this.$path('item.project.contractTemplates.bidFields') || []).find(f => f.startsWith('Bid.condition'))
    },
    showTakeoverAt() {
      return (this.$path('item.project.contractTemplates.bidFields') || []).find(f => f.startsWith('Bid.takeoverAt'))
    },
    showExpiresAt() {
      return (this.$path('item.project.contractTemplates.bidFields') || []).find(f => f.startsWith('Bid.expiresAt'))
    },

    fee() {
      return this.$path('item.sale.fee.value')
    },
    jointDebt() {
      return this.$path('item.sale.jointDebt')
    },

    total() {
      let total = this.$path('item.sale.value')
      if (!total) return

      if (!this.$path('item.sale.fee.included') && this.fee) total += this.fee
      if (this.jointDebt) total += this.jointDebt

      return total
    },

    rules() {
      return {
        ...Rules,
        ...(this.showTakeoverAt ? { takeoverAt: 'required' } : {}),
        ...GetCustomFieldsRules(this.customFieldSchemas, { placement: this.customFieldsPlacement }),
      }
    },
  },
  methods: {
    addFinancing() {
      this.value.financing.push(Financing())
    },
    removeFinancing() {
      this.value.financing.pop()
    },
  },
  created() {
    if (this.value.id) this.$emit('next')
  },
  components: {
    Card,
    InputComponent,
    FinancingComponent,
    CustomFields,
    InputDate,
  },
  filters: {
    Currency,
  },
}
</script>

<style lang="scss">
.bid-create-bid-information {
  margin: 0 auto;

  .card__header {
    min-height: 125px;
  }

  h1 {
    margin: 0;
  }

  &__inputs {
    display: grid;
    grid-gap: 1rem 2rem;
    align-items: flex-start;
    grid-template-columns: 1fr;

    @media (min-width: 800px) {
      grid-template-columns: repeat(2, 1fr);
      [data-field='amount'],
      [data-field='condition'] {
        grid-column-end: span 2;
      }
    }

    [data-field='expiresAt'] {
      .elder-input__value {
        @media (max-width: 500px) {
          flex-wrap: wrap;
        }
      }
    }
  }

  .financing + .financing {
    $spacing: 2rem;
    margin-top: $spacing;
    padding-top: $spacing;
    border-top: 1px dashed var(--border-color);
  }

  &__price {
    $gap: 0 0.5rem;
    opacity: 0.8;
    display: flex;

    flex-wrap: wrap;
    margin-bottom: 1rem;
    margin-top: 0.5rem;

    span:not(:last-child) {
      padding: $gap;
      border-right: 1px solid var(--border-color);
    }

    &-total {
      padding: $gap;
      font-weight: bold;
    }
  }

  hr {
    margin: 2rem 0 1.8rem;
  }
}
</style>
