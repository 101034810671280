import moment from 'moment'
import { setByPath, getByPath } from '@/utils'

function DateTransformer(key) {
  let get = function () {
    let value = getByPath(key, this)
    if (!value) return
    let x = moment(value).format('YYYY-MM-DD')
    return x
  }

  let set = function (value) {
    let oldValue = getByPath(key, this) || undefined
    if (!isFinite(oldValue)) oldValue = undefined
    let { hours, minutes } = oldValue ? moment(oldValue).toObject() : moment().startOf('h').toObject()
    let { years, months, date } = moment(value).toObject()

    let fields = [years, months, date]

    if (fields.every(x => !x)) return setByPath(key, null, this)
    if (fields.some(x => [undefined, null].includes(x))) return

    setByPath(key, moment({ years, months, date, hours, minutes }).toDate(), this)
  }

  return { get, set }
}

function TimeTransformer(key, options = {}) {
  let unwatch

  let get = function () {
    if (options.date && !unwatch) {
      unwatch = this.$watch(options.date, () => set.call(this, get.call(this)))
    }

    let value = getByPath(key, this)
    if (!value) return
    return moment(value).format('HH:mm')
  }

  let set = function (value) {
    if (!value) return
    let base = getByPath(options.date || key, this)
    let { years, months, date } = moment(base).toObject()
    let [hours, minutes] = value.split(':').map(v => parseInt(v) || 0)

    setByPath(key, moment({ years, months, date, hours, minutes }).toDate(), this)
  }

  return { get, set }
}

function NumberTransformer(key) {
  let get = function () {
    return getByPath(key, this) || null
  }

  let set = function (value) {
    let parsed = parseInt(value)
    if (isNaN(parsed)) parsed = null
    setByPath(key, parsed, this)
  }

  return { get, set }
}

export { DateTransformer, TimeTransformer, NumberTransformer }
