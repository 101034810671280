import Factory from './utils/factory'
import Address from './Address'

export default Factory({
  socialSecurityNumber: null,
  name: null,
  email: null,
  phone: null,
  isCompany: false,
  organizationNumber: null,
  companyName: null,
  address: Address(),
})
