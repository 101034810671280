<template>
  <div class="elder-input__element input-date">
    <input :value="formatted" :placeholder="$t('select') + '...'" readonly type="text" ref="dd" />
    <button v-if="value" @click="$emit('input', '')" type="button" class="input-date__reset">
      <Icon :icon="['fal', 'times']" />
    </button>
  </div>
</template>

<script>
import DateDropper from '@/plugins/datedropper'
import moment from 'moment'

function OptionsHandler() {
  const Config = [
    { key: 'value', mapTo: 'defaultDate', use: 'defaultDate', prop: { type: String } },
    { key: 'defaultDate', mapTo: 'defaultDate' },
    { key: 'min', mapTo: 'minDate', use: 'minComputed', prop: { type: String } },
    { key: 'max', mapTo: 'maxDate', prop: { type: String } },
    { key: 'expanded', mapTo: 'expandedDefault', prop: { type: Boolean } },
    { key: 'expandable', prop: { type: Boolean, default: true } },
    { key: 'overlay', prop: { type: Boolean, default: true } },
    { key: 'showArrowsOnHover', prop: { type: Boolean, default: true } },
    { key: 'doubleView', prop: { type: Boolean } },
    { key: 'expandedOnly', prop: { type: Boolean } },
    { key: 'disabledDays', prop: { type: Array } },
    { key: 'enabledDays', prop: { type: Array } },
    { key: 'range', prop: { type: Boolean } },
    { key: 'minRange', prop: { type: String } },
    { key: 'maxRange', prop: { type: String } },
    { key: 'startFromMonday', prop: { type: Boolean, default: true } },
  ]

  return {
    watchers: Config.reduce((res, cur) => {
      let { key } = cur
      let { mapTo = key, use = key } = cur

      res[key] = function() {
        this.trigger('set', { [mapTo]: this[use] })
      }

      return res
    }, {}),
    props: Config.reduce((res, cur) => {
      let { prop, key } = cur

      if (prop) res[key] = prop

      return res
    }, {}),
    getOptions: instance => {
      return Config.reduce((res, cur) => {
        let { key } = cur
        let { mapTo = key, use = key } = cur

        res[mapTo] = instance[use]

        return res
      }, {})
    },
  }
}

export default {
  props: {
    ...OptionsHandler().props,
  },
  watch: {
    ...OptionsHandler().watchers,
  },
  computed: {
    hasValue() {
      return this.value && this.value !== '-'
    },
    minComputed() {
      if (!this.min) return
      return moment(this.min)
        .subtract(1, 'd')
        .format('yyyy-MM-DD')
    },
    formatted() {
      if (!this.hasValue) return
      return moment(this.value).format('D. MMMM YYYY')
    },
    defaultDate() {
      if (this.min) return moment.max(moment(this.min), moment(this.value)).format('yyyy-MM-DD')
      if (this.max) return moment.min(moment(this.max), moment(this.value)).format('yyyy-MM-DD')
      return this.value || moment().format('yyyy-MM-DD')
    },
  },
  methods: {
    trigger(event, data) {
      if (!this.$refs.dd.datedropper) return
      this.$refs.dd.datedropper(event, data)
    },
    init() {
      this.trigger('destroy')
      new DateDropper({
        lang: document.documentElement.lang,
        selector: this.$refs.dd,
        format: 'd. MM y',
        autoFill: false,
        ...OptionsHandler().getOptions(this),
        onChange: res => {
          let { output } = res
          this.$emit('input', [output.y, output.mm, output.dd].join('-'))
        },
      })
    },
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    this.trigger('destroy')
  },
}
</script>

<style lang="scss">
.input-date {
  display: flex;
  align-items: center;
  cursor: pointer;

  padding: 0 !important;

  input {
    flex-grow: 1;
    padding: 1.1em;
    cursor: inherit;
    outline: none !important;
  }

  &__reset {
    opacity: 0.4;
    margin: 0 0.5rem;
    padding: 0 0.5rem;
    cursor: pointer;
    transition: opacity 150ms ease;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
