import Factory from './utils/factory'
import Person from './Person'

export default Factory({
  applicant: Person(),
  hasCoApplicant: false,
  coApplicant: Person(),
  amount: null,
  expiresAt: null,
  takeoverAt: null,
  condition: null,
  financing: [],
  customFields: {},
})
