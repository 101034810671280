<template>
  <Loader
    :value="promises.fetchOne"
    theme="default"
    class="bid-create view"
    :message="$t('loading', { resource: this.$tc('residential').toLowerCase() }) + '...'"
  >
    <div class="container">
      <div class="bid-create__metadata">
        <ResidentialCard :value="item" />
        <SellerCard :value="$path('project.roles.salesman.0', item)" />
      </div>
      <StepperComponent v-if="!error && item.id" :value="steps" ref="stepper" state-handler="none">
        <template #breadcrumb="{ item, index, isActive, navigate }">
          <button
            type="button"
            :disabled="!validSteps.includes(item.id) && !isActive"
            class="elder-stepper__breadcrumb-button"
            @click="navigate"
          >
            <span class="elder-stepper__breadcrumb-icon">
              {{ index + 1 }}
            </span>
            <span>{{ item.label }}</span>
          </button>
        </template>
        <template #navigation="{ meta, next, prev, item }">
          <CheckboxComponent v-model="privacyAccepted" required style="margin-right: auto;">
            <i18n path="contractPrivacy" tag="span">
              <a href="/api/legal/privacy/tenant" target="_blank">{{ $t('privacy').toLowerCase() }}</a>
            </i18n>
          </CheckboxComponent>
          <ButtonComponent
            v-if="meta.prev"
            :label="$t('prev')"
            type="submit"
            :icon="['fal', 'angle-left']"
            icon-placement="left"
            @click="prev"
          />
          <ButtonComponent
            v-if="meta.next"
            :label="$t('next')"
            type="submit"
            theme="primary"
            :disabled="!validSteps.includes(item.id) || !privacyAccepted"
            :icon="['fal', 'angle-right']"
            @click="next"
          />
        </template>
      </StepperComponent>
      <Alert v-if="error" theme="error" class="mt-3">
        {{ error }}
      </Alert>
    </div>
  </Loader>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Validator from '@/mixins/validator'
import StorageMixin from '@/mixins/storage'
import { mapi18n } from '@/i18n'
import Model from '@/models/Bid'
import { GetCustomFieldsRules } from '@kvass/custom-fields/src/utils'

import { getValidatorConfig } from '@kvass/custom-fields'

import { StepperComponent } from 'vue-elder-stepper'
import Steps from './Steps'
import ResidentialCard from '@/components/Residential/Card'
import SellerCard from '@/components/Residential/Seller'
import { AlertComponent as Alert } from 'vue-elder-alert'
import { CheckboxComponent } from 'vue-elder-checkbox'
import { loadLanguageAsync } from '@/i18n'

export default {
  mixins: [Validator, StorageMixin('data', 'bidData')],
  props: {
    id: String,
  },
  watch: {
    id: {
      handler: 'fetch',
      immediate: true,
    },
  },
  data() {
    return {
      validator: {
        rules: 'rules',
        data: 'data',
      },
      validSteps: [],
      data: Model(),
      privacyAccepted: false,
    }
  },
  computed: {
    ...mapState('Residential', ['item', 'promises']),
    error() {
      if (!this.item.id) return false
      if (!['sale', 'reserved'].includes(this.$path('item.status'))) return this.$t('bidErrorStatus')
      if (!this.addons.includes('project:bid')) return this.$t('bidErrorAddon')
      return false
    },
    addons() {
      return this.$path('item.project.metadata.addons') || []
    },

    customFieldValidatorConfig() {
      if (!this.customFieldSchemas.length) return {}
      return getValidatorConfig(this.customFieldSchemas, this.data, { prefix: 'customFields' })
    },

    customFieldSchemas() {
      if (!this.item.project) return []
      return this.item.project.customFieldsConfig.map(c => c.schema).flat()
    },
    rules() {
      return {
        ...Steps().reduce((res, cur) => {
          if (cur.rules) res = { ...res, ...cur.rules }
          return res
        }, {}),
        ...GetCustomFieldsRules(this.customFieldSchemas),
      }
    },
    steps() {
      let props = {
        value: this.data,
        customFieldsLabels: this.customFieldValidatorConfig.labels,
        customFieldSchemas: this.customFieldSchemas,
      }

      return Steps().map(raw => {
        const { icon, ...s } = raw
        s.props = {
          id: raw.id,
          ...(s.props || {}),
          ...props,
          icon,
        }
        s.listeners = {
          ...(s.listeners || {}),
          completed: () => {
            this.clearStorage()
          },
          'update:isValid': state => {
            if (state && !this.validSteps.includes(raw.id)) return this.validSteps.push(raw.id)
            if (!state) return (this.validSteps = this.validSteps.filter(v => v !== raw.id))
          },
        }
        return s
      })
    },
  },
  methods: {
    ...mapActions('Residential', {
      fetch(dispatch) {
        const notFound = () => this.$router.replace({ name: 'notfound' })

        return dispatch('fetch', { id: this.id, saleValueInclude: [] })
          .then(() => {
            if (!this.item.id) return notFound()
            loadLanguageAsync(this.$path('item.project.metadata.language', this))
          })
          .catch(notFound)
      },
    }),
  },
  metaInfo() {
    return {
      title: this.item.id
        ? `${this.$t('addBid')} | ${this.item.name} - ${this.item.project.name}`
        : this.$t('loading', { resource: this.$t('bidContract').toLowerCase() }) + '...',
    }
  },
  components: {
    StepperComponent,
    ResidentialCard,
    SellerCard,
    Alert,
    CheckboxComponent,
  },
}
</script>

<style lang="scss">
.bid-create {
  // background-color: var(--light-grey);
  min-height: 100vh;

  @include respond-below('phone') {
    padding: 0;
  }

  .container {
    max-width: 950px;
  }

  .elder-stepper__breadcrumb-button:disabled {
    cursor: not-allowed;
  }

  .elder-stepper__breadcrumbs {
    margin: 1.5rem 0;

    .elder-stepper__breadcrumb {
      padding: 0.5rem 0;
    }
  }

  .elder-stepper__component {
    @include respond-below('phone') {
      margin: 0 -2rem;
    }
  }

  .elder-stepper__component > .card > .card__footer > .card__footer-content {
    align-items: flex-start;

    input:not(:checked) + .elder-checkbox__box {
      background-color: white;
    }
  }

  @include respond-below('phone') {
    .elder-stepper__component .card {
      border-left: none;
      border-right: none;
    }
  }

  .card__header {
    h1 {
      font-size: 1.5em;
    }
  }

  &__metadata {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    margin: 2rem auto 0;
  }
}
</style>
